import React from 'react';
import ReactDom from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import AppContainer from './components/appContainer';


const _render = () => ReactDom.hydrate((
    
    <div>
        <BrowserRouter >
            <AppContainer ></AppContainer>
        </BrowserRouter>
    </div>
), document.getElementById('root'));
_render();