import React from 'react';
import { NavLink } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';


class MenuPanel extends React.Component {
    render(){
        const linkStyle = {
                textDecoration: 'none'
        }
      return (
        <div style={{margin:'8px', fontWeight: '500' }}>
            <p><NavLink to="/" style={linkStyle}>Home</NavLink></p>
            <p><NavLink to="/music" style={linkStyle}>Music</NavLink></p>
            <p><HashLink to="#contact" style={linkStyle}>Contact Us</HashLink></p>
        </div>
      );
    }
  };
  export default MenuPanel;