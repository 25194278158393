import React from 'react';

//import '../styles/styles.css';


class GridLayout extends React.Component {
  constructor(props) {
    super(props);
  }
    render(){
      const styles = {
        display: this.props.display || 'grid',
        gridGap: this.props.gridGap || '0px',
        gridTemplateColumns: this.props.gridTemplateColumns,
        gridTemplateRows: this.props.gridTemplateRows,
        ...this.props.style
      }
      return (
        <div style={styles}>
          {this.props.children}
        </div>
      );
    }
  };

  export default GridLayout;