import React from 'react';
import { render } from 'react-dom';
import {default as GridGallery} from 'react-grid-gallery';

class Gallery extends React.Component {
    render() {
        const IMAGES =
            [            {
                src: "IMG_4264.jpg",
                thumbnail: "IMG_4264_t.jpg",
                thumbnailWidth: 320,
                thumbnailHeight: 213,
                caption:"📷 @grizzleemartin"
            },
            {
                src: "IMG_4255.jpg",
                thumbnail: "IMG_4255_t.jpg",
                thumbnailWidth: 213,
                thumbnailHeight: 320,
                caption:"📷 @grizzleemartin"
            },
            {
                src: "IMG_4260.jpg",
                thumbnail: "IMG_4260_t.jpg",
                thumbnailWidth: 320,
                thumbnailHeight: 213,
                caption:"📷 @grizzleemartin"
            },
            {
                src: "IMG_4254.jpg",
                thumbnail: "IMG_4254_t.jpg",
                thumbnailWidth: 320,
                thumbnailHeight: 213,
                caption:"📷 @grizzleemartin"
            }

        ]

        return (
            <GridGallery style={{display:"flex", justifyContent:"center"}} images={IMAGES} />
        );
    }
};
export default Gallery;