import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Home from '../routes/home/home';
import MusicPage from '../routes/music/music';
import Contact from '../routes/contact/contact';

class ContentPane extends React.Component {
    constructor(props) {
        super(props);
      }
    render() {
        const linkStyle = {
            textDecoration: 'none'
        }
        return (
            <div>
                <Switch>
                    <Route exact path='/music' component={MusicPage} />
                    <Route exact path='/' component={Home} />
                </Switch>
            </div>
        );
    }
};
export default ContentPane;