import React from 'react';
import Gallery from '../../gridGallery/grid-gallery';
import ProfileCard from '../../profileCard/profileCard';
import { ImageSlider, ImageSlide } from '../../imageSlider/react-slider';
import EmbeddedVideoPlayer from '../../EmbeddedVideoPlayer/embedded-video-player';

class HomePage extends React.Component {
    render() {

        return (
            <div >
                <h1>Welcome to the home for the Company of the Steel Hound!</h1>
                <EmbeddedVideoPlayer source="https://www.youtube.com/embed/g2nLsU6djS0"></EmbeddedVideoPlayer>
                <div>
                    <h1>Meet the Company</h1>
                    <ImageSlider>
                        <ImageSlide><ProfileCard imgUrl="/Whitney-Col.png" name="Whitney" title="Vocals, Banjo, Ukulele, Guitar"></ProfileCard></ImageSlide>
                        <ImageSlide><ProfileCard imgUrl="/Jesse-Col.png" name="Jesse" title="Guitar, vocals, Steel Guitar, Mandolin"></ProfileCard></ImageSlide>
                        <ImageSlide><ProfileCard imgUrl="/Matt-Col.png" name="Matt" title="Guitar, Vocals"></ProfileCard></ImageSlide>
                        <ImageSlide><ProfileCard imgUrl="/Abe-Col.png" name="Abe" title="Upright Bass, Guitarron"></ProfileCard></ImageSlide>
                        <ImageSlide><ProfileCard imgUrl="/Trey-Col.png" name="Trey" title="Drums, Percussion"></ProfileCard></ImageSlide>
                    </ImageSlider>
                </div>
                <div>
                    <h1>About Us</h1>
                    <p>The Company of the Steel Hound is a country-western band that is sure to become one of your favorites. Come sing and dance the night away with us as we bring you all the hits of yesteryear (and some from today)! Our vast repertoire of material, spans from the era of true classic country to the modern era. We cover Hank Williams and Patsy Cline all the way to Garth Brooks, Blake Shelton, and Taylor Swift. That’s right folks, we really do it all! And we don’t just stop at country and western. There is everything in between. We are a multi-talented group of professional musicians with a range of vocals and instruments that provide unique sounds and keep songs fun and interesting. So whether you’ve got a hankerin’ for the exciting sounds of the roaring 20’s, a luau under the summer moon, or just love that old time Rock and Roll, we have got you covered!</p>
                    <p>Be sure to connect with us and stay up to date on all the latest shows and events we have planned. We hope to see you out at one of our shows!</p>
                </div>
                <p>
                    <Gallery />
                </p>
            </div>
        );
    }
};
export default HomePage;