import React from 'react';

class PageHeader extends React.Component {
    constructor(props) {
        super(props);
      }
    render() {
        const linkStyle = {
            textDecoration: 'none'
        }
        return (
            <div>
                <img src="Header-250h.png" style={{objectFit:'cover', height:'250px', width:'100%', display:"inline"}} ></img>

            </div>
        );
    }
};
export default PageHeader;