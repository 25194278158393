import React from 'react';
import {faInstagram, faTwitter} from '@fortawesome/free-brands-svg-icons';
import {faEnvelope} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

class Contact extends React.Component {
    render() {
        return (
            <div >
                <p><FontAwesomeIcon icon={faInstagram}/><a href="https://www.instagram.com/steelhoundco/" target="_blank"> @steelhoundco</a></p>
                <p><FontAwesomeIcon icon={faTwitter}/> <a href="https://twitter.com/steelhoundco" target="_blank"> @steelhoundco</a></p>
                <p><FontAwesomeIcon icon={faEnvelope}/><a href="mailto:booking@steelhound.co" target="_blank"> booking@steelhound.co</a></p>
            </div>
        );
    }
};
export default Contact;