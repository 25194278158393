import React from 'react';
import styles from './embedded-video-player.module.css';

class EmbeddedVideoPlayer extends React.Component {
    constructor(props) {
        super(props);
      }
    render() {
        return (
            <div className={styles['video-container']}>
                <iframe width={this.props.width} height={this.props.height} src={this.props.source} frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen loading="lazy:"></iframe>
            </div>

        );
    }
};
export default EmbeddedVideoPlayer;