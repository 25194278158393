// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._15wWecVB6_jXx6gHQ2zJhu {\n    width: inherit;\n    height: fit-content;\n    display: flex;\n    overflow-x: scroll;\n    overflow-y: hidden;\n    white-space: nowrap;\n    scroll-snap-type: x mandatory;\n    padding: 5px;\n    justify-content: center;\n  }\n  .i96Ha3-DbLHH0YugrhZ-w {\n    width: min-content;\n    flex-shrink: 0;\n    height: 100%;\n    display: inline-block;\n    white-space: normal;\n  }\n  .i96Ha3-DbLHH0YugrhZ-w > div {\n    scroll-snap-align: start;\n  }\n  ", ""]);
// Exports
exports.locals = {
	"slider": "_15wWecVB6_jXx6gHQ2zJhu",
	"slide": "i96Ha3-DbLHH0YugrhZ-w"
};
module.exports = exports;
