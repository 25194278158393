import React from 'react';

class MusicPage extends React.Component {
    render() {
        const linkStyle = {

        }
        return (
            <div >
                <h1>Our Music</h1>
                <p>We play Country, Western, Swing and Rock n Roll from the mid 20th century. We can play as a one piece, or eight piece or any combination in between. Below are a few videos which demonstrate the Steel Hound sound.</p>
                <video style={{width:"320", height:"240"}} controls>
                    <source src="lottarhythm.mp4" type="video/mp4" />
                            Your browser does not support the video tag. Please consider using a modern web browser.
                </video>
                <p>Gotta Lotta Rhythm in my Soul</p>
                <video style={{width:"320", height:"240"}} controls>
                    <source src="youbetternotdothat.mp4" type="video/mp4" />
                            Your browser does not support the video tag. Please consider using a modern web browser.
                </video>
                <p>You Better not Do That</p>
            </div>
        );
    }
};
export default MusicPage;