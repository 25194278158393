// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1QDadcmFLemhTujiUUD4ZH {\n    overflow: hidden;\n    position: relative;\n    width:100%;\n  }\n  \n  ._1QDadcmFLemhTujiUUD4ZH::after {\n    padding-top: 56.25%;\n    display: block;\n    content: '';\n  }\n  \n  ._1QDadcmFLemhTujiUUD4ZH iframe {\n    position: absolute;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n  }", ""]);
// Exports
exports.locals = {
	"video-container": "_1QDadcmFLemhTujiUUD4ZH"
};
module.exports = exports;
