import React from 'react';

class ProfileCard extends React.Component {
    constructor(props) {
        super(props);
      }
    render() {
        const avatarStyle = {
            borderRadius: '50%',
            borderColor:"WhiteSmoke",
            margin: "auto",
            height: "10em"
        }
        const profileDetail = {
            textAlign:'center'
        }
        return (
            <div style={{width:"fit-content", padding:".5em", display:"inline"}}>
                <img src={this.props.imgUrl} style={avatarStyle}></img>
                <h2 style={profileDetail}>{this.props.name}</h2>
                <p style={profileDetail}>{this.props.title}</p>
            </div>
        );
    }
};
export default ProfileCard;