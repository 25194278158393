import React from 'react';

//import '../styles/styles.css';


class GridCell extends React.Component {
    render(){
      const styles = {
        gridColumn: this.props.gridColumn,
        gridRow: this.props.gridRow,
        ...this.props.style
      }
      return (
        <div style={styles}> 
          {this.props.children}
        </div>
      );
    }
  };
  export default GridCell;