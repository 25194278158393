import React from 'react';
import styles from './imageSlider.module.css';


class ImageSlider extends React.Component {
  constructor(props) {
    super(props);
  }
    render(){
      console.log(styles);
    
      return (
        <div className={styles.slider}>
          {this.props.children}
        </div>
      );
    }
  };

  export default ImageSlider;