import React from 'react';
import { hydrate } from 'react-dom';
import { GridLayout, GridCell } from './gridLayout/react-grid';
import MenuPanel from './menuPanel/menupanel';
import ContentPane from './contentPane/content-pane';
import PagerHeader from './pageHeader/page-header';
import {faInstagram, faTwitter, faFacebook} from '@fortawesome/free-brands-svg-icons';
import {faEnvelope} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

class AppContainer extends React.Component {
  render() {
    const footerContent = {
      textAlign: "center"
    }
    const listStyle = {
      display:"inline",
      padding:"0.25em"
    }
    const linkStyle = {
      textDecoration: "none"
    }

    return (
      <div className="appBody">
        <GridLayout gridGap="8px" gridTemplateColumns="1fr 4fr 1fr" gridTemplateRows="250px auto 100px">
          <GridCell gridColumn="1/4" gridRow="1/2" >
            <PagerHeader />
          </GridCell>
          <GridCell gridColumn="1/2" gridRow="2/3"><MenuPanel></MenuPanel></GridCell>
          <GridCell gridColumn="2/2" gridRow="2/3">
            <ContentPane />
          </GridCell>
          <GridCell>

          </GridCell>
          <GridCell gridColumn="1/4" gridRow="3/3" >
            <h1 id="contact">Say Hello!</h1>
            <p style={footerContent}>
              <ul>
                <li style={listStyle}><FontAwesomeIcon icon={faEnvelope}/><a style={linkStyle} href="mailto:booking@steelhound.co" target="_blank"> booking@steelhound.co</a></li>
                <li style={listStyle}>|</li>
                <li style={listStyle}><FontAwesomeIcon icon={faInstagram}/> <FontAwesomeIcon icon={faTwitter}/> <FontAwesomeIcon icon={faFacebook} /> steelhoundco </li>
              </ul> 
            </p>
          </GridCell>
        </GridLayout>
      </div>

    );
  }
};
export default AppContainer;