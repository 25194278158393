import React from 'react';
import styles from './imageSlider.module.css';


class ImageSlide extends React.Component {
  constructor(props) {
    super(props);
  }
    render(){

      return (
        <div className={styles.slide}>
          {this.props.children}
        </div>
      );
    }
  };

  export default ImageSlide;